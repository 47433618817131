@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');

.podcast-container {
  top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  position: relative;
  overflow-y: auto;
  max-width: 100%;
  padding: 5px 0;
  box-sizing: border-box;
  max-height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
  overflow-x: hidden;
}

.podcast-container::-webkit-scrollbar {
  display: none;
}
.podcast-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.podcast-container .background {
  background-image: url('./../../../public/images/gallary_room.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: blur(1.8px);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Header (No Black Box) */
.podcast-container .header {
  color: #fff;
  text-align: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.podcast-container  .content-text {
    background: linear-gradient(110deg, 
    #e1a700 -10%,
    #ffd700 5%,
    #ffd700 20%,
    #f6eb98 48%, 
    #ffd700 60%,
    #ffcc00 68%,
    #e1a700 73%,
    #ba8001 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.podcast-container  .podcast-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 5px;
}

.podcast-container  .follow-text {
  font-size: 1.8rem;
  margin: 0 0 5px 0;
  font-family: 'Great Vibes', cursive;
}

.podcast-container .follow-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 45px;
}

.podcast-container .social-buttons {
  display: flex;
  gap: 15px;
}

.podcast-container .social-button {
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  color: white;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.podcast-container .social-button:hover {
  transform: scale(1.1);
}

.podcast-container .spotify { background-color: #1DB954; }
.podcast-container .spotify:hover { background-color: #1ed760; }
.podcast-container .youtube { background-color: #FF0000; }
.podcast-container .youtube:hover { background-color: #cc0000; }
.podcast-container .apple { background-color: rgb(0, 0, 0); }
.podcast-container .apple:hover { background-color: #4c4c4c; }

.podcast-container .separator {
  font-size: 1.5rem;
  font-family: 'Great Vibes', cursive;
}

.podcast-container .email-form {
  display: flex;
  gap: 10px;
}

.podcast-container .email-input {
  padding: 8px;
  border: none;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 0.75rem;
  width: 160px;
}

.podcast-container .follow-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
}

.podcast-container .follow-btn:hover {
  background: #0056b3;
}

/* Black Box for Description and Episodes */
.podcast-container .text-box.block {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 15px;
  margin: 5px auto 15px;
  border-radius: 6px;
  width: auto;
  max-width: 650px;
  position: relative;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.podcast-container .podcast-overview {
  font-size: 0.7rem;
  margin: 0;
}

.podcast-container .search-filter-container {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  gap: 10px;
  height: 28px;
  margin: 0 auto 15px;
}

/* Styled search-bar container */
.podcast-container .search-filter-container .search-bar {
  display: flex;
  height: 28px; /* Match the container height */
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.podcast-container .search-filter-container .search-bar input {
  padding: 2px 6px; /* Reduced padding to fit within 28px height */
  border: none;
  background: transparent;
  color: black;
  font-size: 0.9rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  align-items: center;
  justify-content: center;
}

.podcast-container .search-filter-container .filter-options {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  justify-content: flex-start;
}

.podcast-container .search-filter-container .filter-select {
  padding: 2px 6px; /* Reduced padding to match search-bar input */
  border: none;
  border-radius: 4px;
  background: rgba(50, 50, 50, 0.9);
  color: white;
  font-size: 0.9rem;
  width: 130px;
  height: 28px; /* Match height */
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.podcast-container .search-filter-container .topic-filter {
  position: relative;
}

.podcast-container .search-filter-container .filter-btn {
  padding: 2px 10px; /* Reduced padding to match search-bar input */
  border: none;
  border-radius: 4px;
  background: rgba(50, 50, 50, 0.9);
  color: white;
  font-size: 0.9rem;
  height: 28px; /* Match height */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 120px;
}

.podcast-container .search-filter-container .filter-btn:hover {
  background: rgba(70, 70, 70, 0.9);
}

.podcast-container .search-filter-container .topic-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(50, 50, 50, 0.9);
  border-radius: 4px;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 3;
  width:100px;
}

.podcast-container .search-filter-container .topic-checkbox {
  display: block;
  margin: 5px 0;
  color: white;
  font-size: 0.9rem;
}

.podcast-container .search-filter-container .topic-checkbox input {
  margin-right: 5px;
}

/* Episode List */
.podcast-container .episode-list {
  width: 90%;
  max-width: 1200px;
  margin: 5px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.podcast-container .episode-box {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  gap: 12px;
  text-decoration: none;
  transition: border 0.3s ease;
}

.podcast-container .episode-box:hover {
    
  border: 1px solid rgba(255, 172, 6, 0.692);
}

.podcast-container .thumbnail-container {
  width: 180px;
  height: 98.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.podcast-container .episode-thumbnail {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.podcast-container .length {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 0.8rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 6px;
  border-radius: 3px;
}

.podcast-container .podcast-container .episode-details {
  flex: 1;
  margin-top: 0;
}

.podcast-container .episode-details h3 {
  font-size: 1.1rem;
  margin: 0 0 3px 0;
}

.podcast-container .episode-details p {
  font-size: 0.8rem;
  margin: 2px 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .podcast-container .podcast-title { font-size: 2.5rem; }
    .podcast-container .podcast-overview { font-size: 0.7rem; }
  .podcast-container .search-filter-container .search-bar { width: 90px; }
  .podcast-container .search-filter-container .filter-select { width: 110px; }
  .podcast-container .follow-text { font-size: 1.8rem; }
  .podcast-container .email-input { width: 140px; }
  .podcast-container .follow-section { flex-wrap: wrap; gap: 15px; }
}

@media (max-width: 740px) {
    .podcast-container .podcast-title { font-size: 2rem; }
    .podcast-container .podcast-overview { font-size: 0.7rem !important; width: 500px;  }
  .podcast-container .search-filter-container .search-bar { width: 100%; max-width: 170px; }
  .podcast-container .search-filter-container .filter-options { justify-content: space-between; gap: 10px; }
  .podcast-container .search-filter-container .filter-select { width: 100%; max-width: 280px; }
  .podcast-container .social-buttons { gap: 10px; }
  .podcast-container .episode-details h3 { font-size: 1.1rem; }
  .podcast-container .episode-details p { font-size: 0.8rem; }
  .podcast-container .thumbnail-container { width: 80px; height: 45px; }
  .podcast-container .email-input { width: 120px; }
  .podcast-container .follow-text { font-size: 1.2rem; }
  .podcast-container .search-filter-container .topic-dropdown { width: 100%; }
}


@media (max-width: 550px) {
  .podcast-container .podcast-title { font-size: 2rem; }
  .podcast-container .podcast-overview { font-size: 0.5rem !important; width: 300px;  }
.podcast-container .search-filter-container .search-bar { width: 100%; max-width: 120px; }
.podcast-container .search-filter-container .filter-options { justify-content: space-between; gap: 10px; }
.podcast-container .search-filter-container .filter-select { width: 100%; max-width: 280px; }
.podcast-container .social-buttons { gap: 10px; }
.podcast-container .episode-details h3 { font-size: 0.7rem; }
.podcast-container .episode-details p { font-size: 0.45rem; }
.podcast-container .thumbnail-container { width: 350px; height: 90px;}
.podcast-container .email-input { width: 120px; }
.podcast-container .follow-text { font-size: 1.2rem; }
.podcast-container .follow-section { flex-direction: column; align-items: center; gap: 10px; }
.podcast-container .search-filter-container .topic-dropdown { width: 60px; }
.podcast-container .search-filter-container .filter-btn { width: 80px; }
}