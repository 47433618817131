/* General Container */
.funds-container {
    top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
    height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    background: transparent;
}


/* Add a wrapper for the table to enable scrolling */
.table-wrapper {
    max-height: 500px; /* Set max height for scrolling */
    overflow-y: auto;  /* Enable vertical scrolling */
    overflow-x: hidden;
    height: auto;
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.table-wrapper::-webkit-scrollbar {
    display: none; /* Hide scrollbar for webkit browsers (Chrome, Safari) */
}

/* Table styling */
.fund-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 6px;
    table-layout: auto;
    box-sizing: border-box;
    font-size: 1.1rem;
}

/* Header row styling (sticky header) */
.fund-table th {
    background: rgb(227, 143, 17); /* Ensure the header has background */
    color: black;
    font-weight: bold;
    cursor: default;
    z-index: 1; /* Keep it on top of table body */
}

/* Table data cell styling */
.fund-table th, .fund-table td {
    padding: 0.7rem;
    border: none;
    box-sizing: border-box;
    word-wrap: break-word; 
    text-align: left;
}

.fund-table td.numeric, th.numeric {
    text-align: right;
}

.fund-table tr {
    background: rgba(197, 133, 36, 0.47);
    position: relative;
    color: var(--logo-color);
    font-weight: 500;
    z-index: 1;
}

/* Hover effect for rows */
.fund-table tbody tr:hover {
    cursor: pointer;
    background: rgba(227, 143, 17, .68); /* Slightly darken the hover color */
}

/* Ensure the opacity only affects the background image */
.funds-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../../../public/images/funds.webp');
    background-size: cover;
    background-position: center;
    filter: blur(1.8px);
    z-index: -1;
}

/* Title and caption styles */
.funds-container .header {
    color: var(--logo-color);
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.funds-container .page-title {
    font-size: 2.8rem; /* Default size */
    font-weight: bold;
}

.funds-container .caption {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 5px;
    color: var(--logo-color);
}

@media (max-width: 768px) {

    .funds-container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }

    .header {
        display: flex !important;
        flex-direction: column;
        text-align: center !important;
    }



    .funds-container .caption {
        font-size: 0.9rem;
        font-weight: normal !important;
        padding-top: 5px;
    }

    .funds-container .page-title {
        font-size: 2.5rem;
    }

    .fund-table th,
    .fund-table td {
        word-wrap: break-word;
        min-width: 20vw;
    }

    .fund-table {
        display: block;
        font-size: 0.9rem;
    }

    .fund-table th,
    .fund-table td {
        display: block;
    }

    .fund-table td {
        position: relative;
        text-align: left;
        padding-left: 40vw;
        text-align: center;
        
    }

    .fund-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .fund-table th {
        display: none;
    }

    .fund-table tr {
        margin-bottom: 1vw;
        background: rgba(197, 133, 36, 0.7);
    }

    .fund-table tbody tr:hover {
        opacity: 1;
    }
        
    .fund-table td.numeric,
    th.numeric {
        text-align: center;
    }

}

/* Mobile (smallest screens) */
@media (max-width: 480px) {
    
    .funds-container .caption {
        font-size: 1rem !important;
    }

    .funds-container .page-title {
        font-size: 32px; /* Adjust title size for small screens */
    }

    .fund-table td {
        font-size: 3vw;
    }

    .fund-table td {
        padding-left: 40vw;
        text-align: center;
    }

    .fund-table td::before {
        font-size: 3vw;
    }
}