/* Ensure styles are scoped specifically to macro page */
.fund-container {
    overflow-x: hidden;
    width: 100vw;
}
  

/******************
    Header
*******************/
  .fund-container .header {
    color: black;
    padding: 70px 80px;
    text-align: left;
    width: 100vw;
}

  .fund-container .header .page-title {
    font-size: 65px;
    font-weight: bold;
  }

  .fund-container  .header .caption {
    font-size: 24px;
    font-weight: bold;
    width: 80vw;
    color: #000;
}


@media (max-width: 900px) {

  .fund-container .header {
    padding-left: 0;
    padding-bottom: 20px;
    text-align: center !important;
  }

.fund-container .header .page-title {
  font-size: 2.8rem;
  font-weight: bold;
}



.fund-container .header .caption {
  font-size: 1rem;
  font-weight: normal;
  color: #000;
  padding-top: 10px;
  width: 80vw !important;
  padding-left: 10vw;
}

}

@media (max-width: 450px) {
    .fund-container .header .page-title {
      font-size: 28px;
      font-weight: bold;
      padding: 0;
    }



  .fund-container .header .caption {
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: -0.3125rem;
    color: #000;
  }

}


/*********************
    Stats Overview
*********************/
.fund-container .overview-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 2 columns on smaller screens */
  gap: 1vw; /* Space between boxes */
  width: 89vw;  /* Allow it to stretch to full width of its container */
  margin: 0 5vw;
  margin-bottom: 8vh;
  justify-items: center; /* Center each box */
  align-items: center;   /* Center the boxes vertically */
}

/* Style for each stat box */
.stat-box {
  background-color: rgba(0, 0, 0, 0.7);
  width: 160px;  /* Fixed smaller width */
  height: 160px; /* Fixed smaller height to maintain square shape */
  position: relative;
  color: var(--logo-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  overflow: hidden;
  border-radius: 0px;  /* Sharp corners */
  font-weight: bold;
}

/* Stat label styling */
.stat-label {
  font-size: 12px;
  color: var(--logo-color);
  margin-bottom: 8px;
  position: absolute;
  top: 10px;
  left: 15px;
}

/* Stat value styling */
.stat-value {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}


@media (max-width: 1100px) {
  .fund-container .overview-container {
    grid-template-columns: repeat(3, 1fr); /* Default: 3 columns */
    gap: 0.5vw; /* Space between boxes */
  }
}

@media (max-width: 600px) {
  .fund-container .overview-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 355px) {
  .fund-container .overview-container {
    grid-template-columns: repeat(1, 6fr);  /* 1 column on very small screens */
  }
}


/********************
Graph & Table Section 
*********************/
/* Graph & Table Section */
.fund-container .graph-table-container {
  display: flex;
  justify-content: space-evenly; /* Ensure equal space between graph and table */
  margin-top: 20px;
  margin-bottom: 40px;
  width: 89vw;
  margin-left: 5.5vw; /* Center the content */
  gap: 2vw; /* Adds space between the graph and table */
}

/* Graph Container */
.fund-container .graph-container {
  width: 700px; /* Fixed width for graph */
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: #aaaaaa;
  flex-grow: 0; /* Prevents graph from stretching */
  flex-shrink: 0; /* Prevents graph from shrinking */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

/* Table Container */
.fund-container .holdings-breakdown .table-container {
  width: 400px; /* Fixed width for table */
  overflow-y: auto;
  height: 500px; /* Set height for consistency */
  flex-grow: 0; /* Prevents table from stretching */
  flex-shrink: 0; /* Prevents table from shrinking */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

/* Ensure both elements are aligned properly */
.fund-container .graph-container, .fund-container .table-container {
  display: flex;
  justify-content: center; /* Ensures that both elements are centered within their containers */
  align-items: center;
}

/* Responsive styles */
@media (max-width: 1250px) {
  .fund-container .graph-table-container {
    flex-direction: column; /* Stack the graph and table */
    justify-content: center; /* Center the content */
    align-items: center; /* Center the graph and table when stacked */
  }

  .fund-container .graph-container {
    width: 90%; /* Take 90% of the screen width on smaller screens */
    margin-left: 0; /* Remove left margin on smaller screens */
    margin-bottom: 20px; /* Add space between graph and table */
  }

  .fund-container .table-container {
    width: 90%; /* Take 90% of the screen width on smaller screens */
    margin-left: 0; /* Remove left margin on smaller screens */
  }
}

/* Other general styles */
.fund-container .interactive-graph {
  width: 100%; /* Ensure it takes full width within the container */
}

.table-container {
  height: max-content; /* Adjust based on content */
}

/* Sticky header for the table */
.holdings-table th {
  background: rgba(0, 0, 0, 1);
  color: var(--logo-color);
  font-weight: bold;
  position: sticky;
  top: 0;
}

/* Table styling */
.category-cell {
  width: 100%;
  padding: 10px;
  padding-left: 30px;
  text-align: left;
  color: var(--logo-color);
}

.holdings-table th {
  
  width: 100%;
  padding: 10px;
  text-align: center;
  color: var(--logo-color);
}

.allocation-cell {
  width: 100%;
  padding: 10px;
  padding-right: 15px;
  padding-left: 20px;
  text-align: right;
  color: var(--logo-color) ;
}

.holdings-table td {
  background: rgba(0, 0, 0, 0.7);
}

.table-container::-webkit-scrollbar {
  width: 8px;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 10px;
}
.table-container::-webkit-scrollbar-track {
  background-color: #222;
  border-radius: 10px;
}
.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/*******************
 Performance Metrics 
 ******************/
  .fund-container .performance-metrics {
    display: flex;
    justify-content: space-around;
    font-size: 1.2em;
    color: #555;
  }
  
  .fund-container .performance-metrics p {
    margin: 0;
    padding: 10px;
  }



  
  