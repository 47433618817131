/* Import FontAwesome for icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/******************
   Navbar styles
*******************/
.navbar {
    background-color: rgba(0, 0, 0, 1);
    padding: var(--navbar-padding); /* padding around entire navbar content */
    position: fixed;
    width: 100vw; /* make sure it takes up the entire width */
    z-index: 1000; /* always in front*/
    height: var(--navbar-height);
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.navbar-content {
    display: flex; /* Flex container for logo and nav items */
    align-items: center; /* Center items vertically */
    width: 100vw; /* Full width */
}

/******************
   Navbar content
*******************/
.logo {
    display: flex;
    align-items: center; /* Center vertically */
    margin-left: 2vw;
    width: 160px; /* prevent pushing rest of navbar right */
    margin-top: -2px;
}

.nav-items {
    display: flex; /* Flex container for nav buttons */
    justify-content: center; /* Center nav items */
    flex: 1; /* Allow nav items to take up remaining space */
}

.hamburger-menu-icon {
    position: absolute;
    top: 15px;
    left: 20px;
    cursor: pointer;
}

/******************
navbar button styles
*******************/
.navbar-button:hover,
.dropbtn:hover {

}

.navbar-button {
    text-decoration: none;
    margin: 0 1.16vw;
    cursor: pointer;
    margin-top: -5px; /* Adjust padding as needed */
    position: relative; /* For positioning the hover effect */
    transition: background-color 0.3s, border-radius 0.3s; /* Smooth transition */
    border-radius: 1vmin; /* Ensure rounded corners persist */
    color: var(--logo-color);
    font-size: 1.2rem;
}

.navbar-button::after {
    content: ''; 
    position: absolute;
    bottom: 0;
    width: 0;
    background: linear-gradient(90deg, 
    #f9efa2 5%, 
    #ffd700 25%,
    #ffcc00 50%,
    #e1a700 65%,
    #cb8b01 95%);
    transition: width 0.3s ease-in-out;
}

.navbar-button:hover::after,
.navbar-button.active::after {
    width: 100%;
    left: 0;
    top: 26px;
    height: 3.5px;
}


/******************
      side menu
*******************/
.side-menu {
    position: fixed;
    top: calc(var(--navbar-height) + var(--navbar-padding) * 2);
    left: -250px; /* Initially hidden off-screen */
    width: 250px;
    height: 100%;
    background-color: #000; /* Black background */
    transition: left 0.3s ease-in-out; /* Smooth sliding effect */
    z-index: 1000; /* Ensure the menu appears above the backdrop */
    display: flex;
    flex-direction: column; /* Align content vertically */
}


.side-menu.open {
    left: 0;
}

.side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-menu ul li {
    text-align: left; /* Left-justify the text */
    align-items: center; /* Align text and icon horizontally */
    padding: 5px 20px; /* Add padding to make the links easier to click */
    justify-content: flex-start;
}

.side-menu ul li a {
    color: var(--logo-color); /* Text color */
    text-decoration: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 5px 0;
    transition: background-color 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.side-menu ul li a:hover,
.side-menu ul li .active {
    transform: scale(1);
}

.side-menu ul li a::after {
    content: ''; 
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    background: linear-gradient(90deg, 
    #f9efa2 5%, 
    #ffd700 25%,
    #ffcc00 50%,
    #e1a700 65%,
    #cb8b01 95%);
    transition: width 0.3s ease-in-out;
}

.side-menu ul li a:hover::after {
    width: 35%;
    top: 35px;
    height: 3.5px;
    
}

.side-menu ul li a.active::after {
    width: 35%;
    top: 35px;
    height: 3.5px;
}

/******************
  smaller screens
*******************/
@media (max-width: 1024px) {
    .nav-items {
        display: none; /* Hide nav items by default */
        flex-direction: column;
        position: absolute;
        top: 60px; /* Adjust position below the navbar */
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    .nav-items.active {
        display: flex;
    }
    
    .hamburger-icon {
        display: flex;
        padding-left: 10px;
    }

    .logo {
        position: absolute;
        width: 140px; /* prevent pushing rest of navbar right */
        left: calc(50% - 70px - 5.2px); /* Center the logo */
        margin-left: 0;
        margin-top: 0;
        top: 8px;
    }

    .navbar-content {
        justify-content: flex-start;
    }
}

@media (max-width: 300px) {

    .logo {
        position: absolute;
        width: 60vw; /* prevent pushing rest of navbar right */
        left: calc(50% - 30vw - 5.2px); /* Center the logo */
        margin-left: 0;
        margin-top: 0;
        top: calc(18.5px - 4vw);
    }
}