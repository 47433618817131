@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');

.tools-container {
    padding-top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.tools-container .background {
    background-image: url('./../../../public/images/office.webp');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Keeps background in place while scrolling */
    filter: blur(1.8px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.tools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 2;
    padding-top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
    max-height: calc(90vh - var(--navbar-height) - 2 * var(--navbar-padding)); /* Adjust based on padding */
    overflow-y: auto; /* Enable vertical scrolling */
    
    /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

/* Specifically hide scrollbar for Webkit browsers */
.tools-grid::-webkit-scrollbar {
    display: none;
}

.category-box {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.85);
    border-radius: 8px;
    padding: 1.5rem;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.category-title {
    font-size: 1.2rem;
    font-family: 'Arial', sans-serif;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 0.5rem;
    color: #fff;
}

.tools-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tool-item {
    margin-bottom: 1.4rem;
    font-size: 0.9rem;
    font-weight: bold;
}

.under-development {
    color: #555 !important;
    font-weight: normal !important;
    font-size: 0.8rem;
}

.tool-link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    font-family: 'Arial', sans-serif;
}

.tool-link:hover {
    color: #4bc0c0;
}

.tool-link.coming-soon,
.tool-link.under-development {
    color: #ccc;
    cursor: default;
}

.status-label {
    font-size: 0.7rem;
    margin-left: 0.5rem;
    color: #ccc;
}

/* Responsive Adjustments */
@media (max-width: 780px) {
    .tools-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;
    }

    .category-title {
        font-size: 1.1rem;
    }

    .tool-item {
        font-size: 0.85rem;
    }

    
    .under-development {
        font-size: 0.7rem;
    }
}

@media (max-width: 550px) {
    .tools-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .category-box {
        padding: 1rem;
    }

    .category-title {
        font-size: 1rem;
    }

    .tool-item {
        font-size: 0.75rem;
    }

    .under-development {
        font-size: 0.65rem;
    }
}