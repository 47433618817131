/* Hamburger menu styles */
.hamburger-menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    font-size: 30px;
}

.hamburger-menu-icon .bar {
    width: 100%;
    border-radius: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger-menu-icon.active .top {
    transform: translateY(10px) rotate(135deg);
}

.hamburger-menu-icon.active .middle {
    opacity: 0;
}

.hamburger-menu-icon.active .bottom {
    transform: translateY(-10px) rotate(-135deg);
}