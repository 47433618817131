@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.efficient-frontier-container {
  top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  position: relative;
  overflow-y: auto;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  max-height: calc(100vh - (var(--navbar-height)) - 2 * var(--navbar-padding));
}

/* Hide scrollbar but keep functionality */
.efficient-frontier-container::-webkit-scrollbar {
  display: none;
}

.efficient-frontier-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.efficient-frontier-container .background {
  background-image: url('./../../../../../public/images/office.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: blur(1.8px);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.efficient-frontier-container .text-box {
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2rem;
  color: #FFF;
  background: rgba(0, 0, 0, 0.75);
  padding: 1.5% 4%;
  text-underline-offset: 6px;
  transform: translateY(0);
  position: relative;
  z-index: 2;
  width: 90%;
  margin: 15px auto;
  max-width: 1200px;
}

.efficient-frontier-container .text-box.block {
  
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 10px 25px 20px;
  margin: 15px auto;
  display: block;
}

.efficient-frontier-container .text-box .title-text {
  padding: 10px;
  font-family: 'Arial', sans-serif;
  z-index: 2;
  width: 100%;
}

.efficient-frontier-container .text-box .cursive-text {
  font-family: 'Great Vibes', cursive;
  font-size: 2.5rem;
  line-height: 0.7;
}

.efficient-frontier-container .efficient-frontier-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.efficient-frontier-container .efficient-frontier-subtitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.efficient-frontier-container .efficient-frontier-subsubtitle {
  font-size: 1.3rem;
  margin-bottom: 8px;
  cursor: pointer;
}

.efficient-frontier-container .efficient-frontier-subsubtitle svg {
  margin-left: 5px;
}

.efficient-frontier-container .efficient-frontier-description {
  font-size: 1rem;
}

.efficient-frontier-container .config-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.efficient-frontier-container .config-row label {
  font-size: 1rem;
  width: 250px;
  text-align: right;
  margin-right: 15px;
}

.efficient-frontier-container .config-row .info-icon {
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  width: 16px;
  text-align: center;
}

.efficient-frontier-container .info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  position: relative;
}

.efficient-frontier-container .info-icon svg {
  color: #555;
}

.efficient-frontier-container .tooltip {
  display: none;
  position: absolute;
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 4px;
  z-index: 20;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem;
  max-width: 500px;
  width: 200px;
  white-space: normal;
  text-align: center;
}

.efficient-frontier-container .info-icon:hover .tooltip {
  display: block;
}

.efficient-frontier-container .tooltip.disabled-tooltip {
  display: none;
}

.efficient-frontier-container .info-icon:hover .tooltip.disabled-tooltip {
  display: none;
}

.efficient-frontier-container .slider:hover + .tooltip.disabled-tooltip {
  display: none;
}

.efficient-frontier-container .clear-btn:hover .tooltip {
  display: block;
}

.efficient-frontier-container .slider {
  width: 220px;
  height: 8px;
  background: #555;
  border-radius: 4px;
  margin: 0;
  margin-right: 14px;
}

.efficient-frontier-container .slider.disabled {
  background: #888;
  opacity: 0.5;
  cursor: not-allowed;
}

.efficient-frontier-container .thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  top: -4px;
  cursor: pointer;
}

.efficient-frontier-container .thumb:disabled {
  background: #ccc;
}

.efficient-frontier-container .track {
  background: #777;
  height: 100%;
}

.efficient-frontier-container .input-field {
  padding: 6px;
  border: none;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  margin: 0;
  font-size: 0.9rem;
  width: 150px;
}

.efficient-frontier-container .expected-return {
  width: 200px;
}

.efficient-frontier-container .disabled-text {
  color: #888;
}

.efficient-frontier-container .assets-table-container {
  width: 100%;
  overflow-x: auto;
}

.efficient-frontier-container .results-table-container {
  overflow-x: auto;
  max-width: 100%;
}

.efficient-frontier-container .assets-table,
.efficient-frontier-container .results-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.efficient-frontier-container .assets-table th,
.efficient-frontier-container .assets-table td,
.efficient-frontier-container .results-table th,
.efficient-frontier-container .results-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.efficient-frontier-container .assets-table th:first-child,
.efficient-frontier-container .assets-table td:first-child {
  width: 30px;
  text-align: center;
}

.efficient-frontier-container .clear-btn {
  cursor: pointer;
  color: red;
  margin-left: 8px;
  position: relative;
}

.efficient-frontier-container .clear-btn .tooltip {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.efficient-frontier-container .add-btn,
.efficient-frontier-container .calculate-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
  margin-right: 8px;
  font-size: 0.9rem;
}

.efficient-frontier-container .calculate-btn:disabled {
  background: #555;
  cursor: not-allowed;
}

.efficient-frontier-container .portfolio-container {
  display: grid;
  grid-template-columns: 1fr minmax(300px, auto);
  gap: 20px;
  align-items: start;
}

.efficient-frontier-container .pie-chart {
  width: 300px;
  height: 300px;
  min-width: 300px;
}

.efficient-frontier-container .chart-container {
  position: relative;
  width: 100%;
  height: 350px;
}

.efficient-frontier-container .loading {
  text-align: center;
  font-size: 1.2rem;
}

.efficient-frontier-container .disclosure {
  font-size: 0.7rem !important;
}

@media (max-width: 1100px) {
  .efficient-frontier-container .text-box {
    font-size: 1.1rem !important;
  }
  .efficient-frontier-container .disclosure {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 900px) {
  .efficient-frontier-container .text-box .title-text .efficient-frontier-description {
    font-size: 0.9rem !important;
  }
  .efficient-frontier-container .text-box {
    width: 82%;
  }
  .efficient-frontier-container .efficient-frontier-title {
    font-size: 1.6rem;
  }
  .efficient-frontier-container .efficient-frontier-subtitle {
    font-size: 1.4rem;
  }
  .efficient-frontier-container .date .input-field {
    width: 90px;
  }
  .efficient-frontier-container .number {
    width: 90px !important;
  }
  .efficient-frontier-container .ticker {
    width: 90px !important;
  }
  .efficient-frontier-container .expected-return {
    width: 90px !important;
  }
  .efficient-frontier-container .efficient-frontier-subsubtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 650px) {
  .efficient-frontier-container .text-box {
    font-size: 0.9rem !important;
    text-underline-offset: 3px !important;
    width: 72%;
  }

  /* Config settings for small screens */
  .efficient-frontier-container .config-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
  }

  .efficient-frontier-container .config-row .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  .efficient-frontier-container .config-row label {
    font-size: 0.95rem;
    width: auto;
    text-align: center;
    margin: 0;
    color: #e0e0e0;
  }
  
  .efficient-frontier-container .slider {
    margin-top: 2.5px !important;
    margin-bottom: 6px !important;
  }


  .efficient-frontier-container .config-row .info-icon {
    margin-left: 8px;
    margin-right: 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  .efficient-frontier-container .config-row .info-icon svg {
    color: #bbb;
  }

  .efficient-frontier-container .config-row > *:not(.label-wrapper) {
    margin: 0 auto;
  }

  .efficient-frontier-container .input-field {
    width: 180px;
    font-size: 0.85rem;
    padding: 8px;
  }

  .efficient-frontier-container .slider {
    width: 180px;
    height: 10px;
  }

  .efficient-frontier-container .thumb {
    width: 18px;
    height: 18px;
    top: -4px;
  }

  .efficient-frontier-container .config-row.date {
    flex-direction: column;
    align-items: center;
  }

  .efficient-frontier-container .config-row.date .date-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .efficient-frontier-container .config-row.date .input-field {
    width: 90px;
    margin: 0 5px;
  }

  .efficient-frontier-container .config-row.date span {
    margin: 0 5px;
    color: #ccc;
  }

  .efficient-frontier-container .config-row input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  /* Other page styling */
  .efficient-frontier-container .assets-table th {
    font-size: 0.7rem;
  }

  .efficient-frontier-container .assets-table tr {
    font-size: 0.7rem;
  }

  .efficient-frontier-container .number {
    width: 30px !important;
    font-size: 0.7rem;
    margin-left: -9px;
  }

  .efficient-frontier-container .ticker {
    width: 38px !important;
    font-size: 0.65rem;
  }

  .efficient-frontier-container .expected-return {
    width: 43px !important;
    font-size: 0.7rem;
    margin-left: -9px;
  }

  .efficient-frontier-container .chart-container {
    height: 450px;
  }

  .efficient-frontier-container .efficient-frontier-title {
    font-size: 1.2rem;
  }

  .efficient-frontier-container .efficient-frontier-subtitle {
    font-size: 1.1rem;
  }

  .efficient-frontier-container .efficient-frontier-subsubtitle {
    font-size: 1rem;
  }

  .efficient-frontier-container .settings {
    align-items: unset !important;
  }
}