/* Initial hidden state for the loader container */
.load-container {
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease-in-out, visibility 0s 0.5s; /* Delay visibility change */
}

.load-container.loaded {
    opacity: 1;
    visibility: visible; /* Make the content visible once loaded */
    transition: opacity 1s ease-in-out, visibility 0s 0s; /* Reset transition delay */
}

/* Center the favicon in the middle */
.favicon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
}

/* Add the grow and shrink animation to the favicon */
.favicon {
    z-index: 10;
    width: 35vh; /* Set the initial size */
    height: auto; /* Set the initial size */
    animation: growShrink 1.5s ease-in-out infinite; /* Infinite animation */
    opacity: 1;
}

/* Define the grow and shrink animation */
@keyframes growShrink {
    0% {
        transform: scale(1); /* Initial size */
    }
    50% {
        transform: scale(1.1); /* Slightly bigger */
    }
    100% {
        transform: scale(1); /* Back to original size */
    }
}
