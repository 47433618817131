@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');

.homepage-container {
    top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
    background: #53320d;
    position: relative;
    overflow: hidden;
    height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
    display: flex;
    align-items: center;
    flex-direction: column;
}


.background {
    width: 100vw;
    height: 100%;
    background-size: 205vh;
    background-position: top;
    position: absolute;
    left: 0;
    z-index: 0;
    background-attachment: fixed;
    overflow: hidden;
    background-image: url('./../../../public/homepage_images/homepage_background.webp');
    filter: blur(3px);
}

.homepage-container .text-box-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
    position: relative;
    justify-content: center;
    z-index: 10;
    padding-bottom: 109vh;
    
}

.homepage-container .text-box-wrapper .text-box {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(136, 136, 136, 0.4);
    border-radius: 6px;
    font-size: 3rem;
    color: #FFF;
    padding: 2% 4%;
    text-underline-offset: 9px;
    position: relative;
    z-index: 2;
    width: 76%;
    margin: 0 auto;
    height: auto;
}

.homepage-container .text-box .title-text {
    padding: 10px;
    font-family: 'Arial', sans-serif;
}

.text-box .cursive-text {
    font-family: 'Great Vibes', cursive; /* Importing and using the Great Vibes font */
    font-size: 4.1rem;
    line-height: 1.1;
}

.text-box .emphasized {
    font-family: 'Georgia', serif; /* Serif font for contrast */
    font-style: italic;
    font-weight: 700;
}

.no-wrap-text {
    white-space: nowrap;
}

.text-box .highlighted-text {
    background-color: rgba(255, 255, 0, 0.6);
    padding: 0 5px;
    font-style: italic;
}

.text-box .underlined {
    text-decoration: underline;
    padding: 0 5px;
}


.arrow-button {
    padding: 17px;
    display: inline-block;
    border-radius: 50%; /* Ensures perfect circle shape */
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
    color: inherit;
    width: 16px; /* Set width and height for a perfect circle */
    height: 16px; /* Set height to the same value as width */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(0,0,0, 0.85); /* Spotify color */
    z-index: 10;
    position: fixed;
    left: calc(50% - 17px - 8px); /* 50% - padding - width/2 */
}

.arrow-button:hover {
    transform: scale(1.1); /* Slight hover effect */
    cursor: pointer;
}

.down-arrow {
    top: 88.5%;
}

.up-arrow {
    top: 11.5%;
}


@media (max-height: 500px) {
    .homepage-container .text-box-wrapper .text-box {
        font-size: 1rem !important;
    }

    
    .homepage-container .text-box-wrapper  .cursive-text {
        font-size: 1.5rem !important; /* Adjust cursive text size */
    }

    .arrow-button {
        transform: scale(0.6); /* Slight hover effect */
    }

    
.arrow-button:hover {
    transform: scale(0.7); /* Slight hover effect */
    cursor: pointer;
}
    
    .down-arrow {
        top: 85%;
    }

    .up-arrow {
        top: 15%;
    }

}

@media (max-width: 1100px) {
    .text-box {
        font-size: 2.2rem !important; /* Adjust title font size */
        text-underline-offset: 5px !important;
    }

    .text-box .cursive-text {
        font-size: 2.9rem !important; /* Adjust cursive text size */
    }
}



@media (max-width: 780px) {
    .text-box {
        font-size: 1.8rem !important; /* Adjust title font size */
        text-underline-offset: 5px !important;
    }

    .text-box .cursive-text {
        font-size: 2rem !important; /* Adjust cursive text size */
    }


}

@media (max-width: 550px) {
    .text-box {
        font-size: 1rem !important; /* Reduce title font size */
        text-underline-offset: 8px !important;
        text-underline-offset: 4px !important;
    }

    .text-box .cursive-text {
        font-size: 1.5rem !important; /* Reduce cursive text size */
        line-height: .95;
    }

    .text-box-wrapper {
        
        padding-bottom: 103vh !important;
    }
    
}


@media (max-width: 400px) {
    .text-box {
        font-size: 0.6rem !important; /* Reduce title font size */
        text-underline-offset: 8px !important;
        text-underline-offset: 4px !important;
    }

    .text-box .cursive-text {
        font-size: 0.9rem !important; /* Reduce cursive text size */
        line-height: .95;
    }
    
}

