.interactive-graph {
  display: flex;
  flex-direction: column;
}

.title {
  margin: 1.5%;
}

.zoom-button {
  background: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  padding-left: 8px;
  color: var(--logo-color);
}

.zoom-button.active {
  color: rgb(251, 251, 251);
  font-weight: bold;      
}

.zoom-button:hover:not(.active) {
  color: #555;
}

.zoom-controls {
  align-items: center;
  margin-left: 0;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}

.check {
  margin-bottom: 5px; /* Adjusted for better spacing */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px; /* Ensure space at top on smaller screens */
  margin-left: 5.5px;
}

.graph-controls {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Reduced bottom margin */
  align-items: flex-start;
  color: var(--logo-color);
  padding: 0 5%; /* Added some padding for better responsiveness */
}

.zoom-controls span {
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
}

.slider-container {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 0;
  padding: 0;
}

input[type="checkbox"] {
  margin-right: 5px;
}

h2 {
  text-align: center;
}

.rc-slider {
  left: 3.5%;
  width: 93%;
  margin-top: -3%;
}

canvas {
  z-index: 10;
}


.rc-slider-rail {
  background-color: #555;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .graph-controls {
    flex-direction: column;
    align-items: center; /* Center the items for smaller screens */
  }

  .zoom-controls {
    justify-content: center;
    margin-top: 5px; /* Reduce the space between zoom buttons and the graph */
    margin-bottom: 5px; /* Reduce the bottom margin on smaller screens */
  }

  .check {
    margin-left: 0; /* Center checkbox */
    margin-bottom: 10px;
  }

  .zoom-button {
    font-size: 12px; /* Slightly smaller font size for buttons */
    margin-bottom: 5px; /* Space between zoom buttons */
  }
}
