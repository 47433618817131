@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');

/* Store.css */

.store-container {
  padding-top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
  overflow: hidden;
  position: absolute;
  width: 100vw;

}

.store-container .background {
  background-image: url('./../../../public/images/books.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: blur(1.8px);
  height: 100%;
  z-index: -1;
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  gap: 10px;  /* Space between input and dropdown */
}

/* Search Input Styling */
.search-bar input {
  padding: 12px 20px;
  height: 50px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 50px;
  width: 75%;
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

/* Focus effect for search input */
.search-bar input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

/* Sort Dropdown Styling */
.search-bar select {
  height: 50px;
  padding: 12px 20px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 50px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
  width: 180px; /* Fixed width for the dropdown */
}

/* Hover and focus effect for dropdown */
.search-bar select:hover,
.search-bar select:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

/* Results Count Styling */
.results-count {
  font-size: 16px;
  color: white;
  margin-left: 10px;
  width: 150px;
}

.store-container .product-list {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  max-height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
  padding: 20px 0;
}

.store-container .product-list::-webkit-scrollbar {
  display: none;
}

.store-container .review-start {
  font-weight: bold;
  font-size: 1.4rem;
}

.store-container .product-card {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.store-container .product-card .card-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}

.store-container .product-card .book-image {
  flex-shrink: 0;
  width: 150px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

.store-container .product-card .text-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.store-container .product-card .review-container {
  margin-top: 20px;
}

.store-container .product-card .expand-review-btn {
  margin-top: 10px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.store-container .product-card .expand-review-btn:hover {
  background-color: #555;
}

.store-container .product-card .specifications {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #ccc;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: rgba(107, 106, 103, 0.833);
  padding: 20px;
  border-radius: 15px;
  max-width: 80%;
  max-height: 90%;
  overflow: hidden;
}



@media (max-width: 1050px) {
     .store-container .product-card  {
      max-width: 520px;
    }

    .store-container .additional-info {
      font-size: 1rem;
      margin-left: 0;
    }

    .store-container .disclaimer {
      font-size: 0.65rem;
    }

    .search-bar {
      max-width: 520px;
      font-size: 12px;
    }

    .search-bar input {
      font-size: 12px;
    }

    .search-bar select {
      font-size: 12px;
    }
    
    .search-bar .results-count {
      font-size: 12px;
    }

    .store-container .product-card .title {
      font-size: 1.4rem;
    }

    .store-container .product-card .author {
      font-size: 1.1rem;
    }
    
    .store-container .product-header .price {
      font-size: 1rem;
      width: 100px;
    }
    
    .store-container .purchase-button {
      font-size: 1rem;
    }

    
    .store-container .review-start {
      font-size: 1.1rem;
    }
    .store-container .product-review {
      font-size: 0.9rem;
    }

    .store-container .product-review-button {
      font-size: 1rem;
    }
}


@media (max-width: 700px) {
  .search-bar {
    max-width: 450px;
  }

  .search-bar input {
    font-size: 9px;
  }

  .search-bar select {
    font-size: 9px;
  }
  
  .search-bar .results-count {
    font-size: 9px;
  }

  .store-container .product-card  {
    max-width: 380px;
  }

 .store-container .additional-info {
   font-size: 0.7rem;
 }

 .store-container .disclaimer {
   font-size: 0.45rem;
 }

 .store-container .product-card .title {
   font-size: 1.3rem;
 }

 .store-container .product-card .author {
   font-size: 0.95rem;
 }
 
 
 .store-container .review-start {
   font-size: 1.1rem;
 }
 .store-container .product-review {
   font-size: 0.9rem;
   max-height: 80px;
 }

 .store-container .product-review-button {
   font-size: 1rem;
 }

 .store-container .product-image img {
    width: 160px;
    height: calc(160px * 1.45);
 }
}


@media (max-width: 525px) {
  .search-bar {
    max-width: 450px;
  }

  .search-bar input {
    font-size: 7px;
  }

  .search-bar select {
    font-size: 9px;
  }
  
  .search-bar .results-count {
    font-size: 9px;
    width: 100px;
  }



  .store-container .product-card  {
    max-width: 250px;
  }
  
  
   .store-container .additional-info {
     font-size: 0.45rem;
     margin-top: 8px;
   }
  
   .store-container .disclaimer {
     font-size: 0.4rem;
   }
  
   .store-container .product-card .title {
     font-size: 1rem;
   }
  
   .store-container .product-card .author {
     font-size: 0.65rem;
   }
   
   .store-container .purchase-button {
    font-size: 1.3rem !important;
    width: 120px !important;
    padding: 5px 5px !important;
  }

   
   .store-container .review-start {
     font-size: 0.95rem;
   }
   .store-container .product-review {
    
     font-size: 0.8rem;
     max-height: 85px;
   }
  
   .store-container .product-review-button {
     font-size: 1rem;
   }
  
   .store-container .product-image img {
     
    width: 120px;
    height: calc(120px * 1.45);
   }
}


