/* ProductCard.css */
.product-card {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(136, 136, 136, 0.4);
}

.disclaimer {
    font-size: 0.6rem;
    margin-top: 10px;
    color: lightgray;
}

/* Card Content (Image on left and text on right) */
.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.row {
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.product-image img {
    width: 160px;
    height: calc(160px * 1.45);
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.product-image {
    position: relative;
  }
  
.product-image .expand-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(68, 68, 68, 0.6); /* Translucent gray */
    color: white;
    padding: 13px 14px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

/* Product Info */
.product-info {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Reduced gap */
    flex-grow: 1;
}

.title {
    width: 100%;
}


.product-card .title-row {
    height: auto;
}

.product-card .author-row {
    height: auto;
    margin-left: 2px;
}

/* Title, Author, Price, and Purchase Button */
.product-card .title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: bold;
}

.product-card .author {
    font-style: italic;
    font-size: 1.1rem;
    color: #ddd;
    margin: 0;
    font-weight: normal;
}

.product-card .purchase-button {
    background-color: #FF9900;
    color: white;
    font-size: 1.2rem;
    padding: 15px 0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 160px;
}

.purchase-button:hover {
    background-color: #cc7a00;
}


/* Review (Expandable) */
.product-review {
    margin-top: -20px;
    max-height: 70px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    width: 100%; /* Make sure it spans the full width */
}

.product-review.expanded {
    max-height: none; /* Or auto, depending on your content */
}

.product-review-button {
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: underline;
    margin-left: -5.5px;
}


/* Specifications */
.additional-info {
    font-size: 1.1rem;
    color: #ccc;
    width: 100%; /* Full width */
    margin-top: 20px;
}

.additional-info div {
    margin-bottom: 2px;
}

.additional-info .category,
.additional-info .keywords,
.additional-info .author-bio,
.additional-info .book-length,
.additional-info .publish-date {
    display: flex;
    flex-wrap: wrap;
}

.additional-info strong {
    color: white;
    margin-right: 5px;
}

.keywords {
    font-size: 0.9rem;
    color: #bbb;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background for emphasis */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the image */
    z-index: 1000;
}

/* Modal Content (Image Only) */
.modal-content {
    position: relative;
    width: auto; /* No fixed width */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image inside modal */
.modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures image fits without distortion */
    max-width: 100%; /* Ensure image doesn't overflow the modal */
    max-height: 100%; /* Ensure image fits within the modal */
}

@media (max-width: 1050) {
    
}
