@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');

.about-container {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    height: 100vh;
}

.about-container .background {
    background-image: url('./../../../public/images/about.webp');
    background-size: cover; 
    background-position: center center;
    background-repeat: no-repeat;
    filter: blur(2.3px);
    height: 100%;
    z-index: -1;
}

.about-container .text-box {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.75);
    padding: 1.5rem;
    border-radius: 8px;
    color: #FFF;
    border: 1px solid rgba(136, 136, 136, 0.4);

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-underline-offset: 9px;
    transform: translateY(0);
    position: relative;
    z-index: 2;
    width: 80%;
    margin: 0 auto;
}

.about-container .text-box .title-text {
    padding: 10px;
    font-family: 'Arial', sans-serif;
    z-index: 2;
}


.about-container .text-box .cursive-text {
    font-family: 'Great Vibes', cursive; /* Importing and using the Great Vibes font */
    font-size: 1.7rem;
    line-height: 0.6;
}

@media (max-width: 1100px) {
    .text-box {
        font-size: 1.1rem !important;
        text-underline-offset: 5px !important;
    }

    .text-box .cursive-text {
        font-size: 1.4rem !important;
    }
}

@media (max-width: 780px) {
    .text-box {
        font-size: 0.85rem !important;
        text-underline-offset: 5px !important;
    }

    .text-box .cursive-text {
        font-size: 1.1rem !important;
    }

}

@media (max-width: 550px) {
    .text-box {
        font-size: 0.7rem !important;
        text-underline-offset: 8px !important;
        text-underline-offset: 4px !important;
    }

    .text-box .cursive-text {
        font-size: 0.95rem !important;
        line-height: .95;
    }
}