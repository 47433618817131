/* Reuse EfficientFrontier.css styles with specific overrides */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=block');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/* Container */
.compound-interest-container {
  top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  position: relative;
  overflow-y: auto;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  max-height: calc(100vh - (var(--navbar-height)) - 2 * var(--navbar-padding));
}

/* Hide scrollbar */
.compound-interest-container::-webkit-scrollbar {
  display: none;
}
.compound-interest-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Background */
.compound-interest-container .background {
  background-image: url('./../../../../../public/images/office.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: blur(1.8px);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Text Box */
.compound-interest-container .text-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2rem;
  color: #FFF;
  background: rgba(0, 0, 0, 0.75);
  padding: 1.5% 4%;
  text-underline-offset: 6px;
  transform: translateY(0);
  position: relative;
  z-index: 2;
  width: 90%;
  margin: 15px auto;
  max-width: 1200px;
}

.compound-interest-container .text-box.block {
  
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 25px 20px;
  border-radius: 8px;
  margin: 15px auto;
  display: block;
}

/* Typography */
.compound-interest-container .text-box .title-text {
  padding: 10px;
  font-family: 'Arial', sans-serif;
  z-index: 2;
  width: 100%;
}

.compound-interest-container .compound-interest-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.compound-interest-container .compound-interest-subtitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.compound-interest-container .compound-interest-subsubtitle {
  font-size: 1.3rem;
  margin-bottom: 8px;
  cursor: pointer;
}

.compound-interest-container .compound-interest-subsubtitle svg {
  margin-left: 5px;
}

.compound-interest-container .compound-interest-description {
  font-size: 1rem;
}

/* Form Config Rows */
.compound-interest-container .config-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.compound-interest-container .config-row .label-wrapper {
  display: flex;
  align-items: center;
  width: 250px;
  text-align: right;
  margin-right: 15px;
}

.compound-interest-container .config-row label {
  font-size: 1rem;
}

.compound-interest-container .config-row .info-icon {
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  width: 16px;
  text-align: center;
}

.compound-interest-container .info-icon svg {
  color: #555;
}

.compound-interest-container .tooltip {
  display: none;
  position: absolute;
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 4px;
  z-index: 20;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem;
  max-width: 200px;
  width: 200px;
  white-space: normal;
  text-align: center;
}

.compound-interest-container .info-icon:hover .tooltip {
  display: block;
}

.compound-interest-container .input-field {
  padding: 6px;
  border: none;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  margin: 0;
  font-size: 0.9rem;
  width: 150px;
}

/* Style select elements specifically */
.compound-interest-container select.input-field {
  background: rgba(50, 50, 50, 0.9); /* Darker background for visibility */
  color: #fff; /* Ensure text is white */
  -webkit-appearance: none; /* Remove default styling on some browsers */
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px; /* Space for custom arrow if added */
}

/* Optional: Add a custom dropdown arrow */
.compound-interest-container select.input-field::-ms-expand {
  display: none; /* Remove default arrow in IE */
}

.compound-interest-container .calculate-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
  margin-right: 8px;
  font-size: 0.9rem;
}

.compound-interest-container .calculate-btn:disabled {
  background: #555;
  cursor: not-allowed;
}

/* Chart */
.compound-interest-container .chart-container {
  position: relative;
  width: 100%;
  height: 350px;
}

/* Disclosure */
.compound-interest-container .disclosure {
  font-size: 0.7rem !important;
}

/* Responsive Design */
@media (max-width: 1100px) {
  .compound-interest-container .text-box {
    font-size: 1.1rem !important;
  }
  .compound-interest-container .disclosure {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 900px) {
  .compound-interest-container .text-box {
    width: 82%;
  }
  .compound-interest-container .compound-interest-title {
    font-size: 1.6rem;
  }
  .compound-interest-container .compound-interest-subtitle {
    font-size: 1.4rem;
  }
  .compound-interest-container .compound-interest-subsubtitle {
    font-size: 1.2rem;
  }
  .compound-interest-container .input-field {
    width: 90px;
  }
}

@media (max-width: 650px) {
  .compound-interest-container .text-box {
    font-size: 0.9rem !important;
    text-underline-offset: 3px !important;
    width: 72%;
  }
  .compound-interest-container .config-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
  }
  .compound-interest-container .config-row .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  .compound-interest-container .config-row label {
    font-size: 0.95rem;
    width: auto;
    text-align: center;
    margin: 0;
    color: #e0e0e0;
  }
  .compound-interest-container .config-row .info-icon {
    margin-left: 8px;
    margin-right: 0;
  }
  .compound-interest-container .input-field {
    width: 180px;
    font-size: 0.85rem;
    padding: 8px;
  }
  .compound-interest-container .compound-interest-title {
    font-size: 1.2rem;
  }
  .compound-interest-container .compound-interest-subtitle {
    font-size: 1.1rem;
  }
  .compound-interest-container .compound-interest-subsubtitle {
    font-size: 1rem;
  }
  .compound-interest-container .chart-container {
    height: 450px;
  }
}