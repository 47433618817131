/* src/components/Podcast/EpisodePage.css */
.episode-page {
  position: relative;
  margin: 0 auto;
  top: calc(var(--navbar-height) + 2 * var(--navbar-padding));
  font-family: 'Arial', sans-serif;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: calc(100vh - var(--navbar-height) - 2 * var(--navbar-padding));
  width: 100%;
}

.episode-page .background {
  background-image: url('./../../../public/images/gallary_room.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: blur(1.8px);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.episode-page .back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: #d4a017;
  font-size: 1.2rem;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 10;
}

.episode-page .back-button:hover {
  background: rgba(0, 0, 0, 0.9);
}

.episode-page .media-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 10px 10px;
  border: 1px solid rgba(136, 136, 136, 0.4);
  border-radius: 12px;
  overflow: hidden;
}

.episode-page .react-player {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.episode-page .media-placeholder {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  background: #000;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

.episode-page .podcast-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 100%;
  object-fit: contain;
}

.episode-page .media-error {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  color: #fff;
  border-radius: 12px;
  text-align: center;
  padding: 20px;
  position: relative;
}

.episode-page .controls-overlay {
  position: absolute;
  bottom: 0; /* Anchor to bottom */
  left: 0;
  width: 96.5%; /* Match your existing width */
  height: 35px; /* Fixed height for controls area, adjust as needed */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7); /* Dark tint */
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease; /* Fade effect */
  z-index: 5;
}

.episode-page .controls-overlay.show {
  opacity: 1;
  pointer-events: auto;
}

.episode-page .controls-overlay.hide {
  opacity: 0;
  pointer-events: none;
}

.episode-page .progress-bar {
  margin: 0 0 5px 0; /* Small margin below progress bar */
  width: 100%;
  height: 3px;
  cursor: pointer;
  appearance: none;
  background: linear-gradient(to right, #d4a017, #b58900);
  border-radius: 2px;
  outline: none;
}

.episode-page .progress-bar::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  background: #d4a017;
  border-radius: 50%;
  cursor: pointer;
}

.episode-page .progress-bar::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #d4a017;
  border-radius: 50%;
  cursor: pointer;
}

.episode-page .controls-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;
}

.episode-page .left-controls {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
}

.episode-page .control-btn {
  padding: 8px;
  border: none;
  background: none;
  color: #d4a017;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s;
}

.episode-page .control-btn:hover {
  color: #b58900;
}

.episode-page .volume-control {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #d4a017;
}

.episode-page .volume-slider {
  width: 60px;
  cursor: pointer;
  accent-color: #d4a017;
}

.episode-page .right-controls {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
}

.episode-page .settings-menu {
  position: relative;
}

.episode-page .settings-dropdown {
  position: absolute;
  bottom: 40px;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  padding: 5px 0;
}

.episode-page .settings-option {
  display: block;
  padding: 5px 15px;
  background: none;
  border: none;
  color: #fff;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 0.9rem;
}

.episode-page .settings-option:hover {
  background: #d4a017;
}

.episode-page .settings-option:disabled {
  color: #666;
  cursor: not-allowed;
}

.episode-page .speed-options {
  padding: 5px 10px;
}

.episode-page .speed-options label {
  margin-right: 10px;
  font-size: 0.9rem;
}

.episode-page .speed-options button {
  margin-right: 5px;
  padding: 2px 8px;
}

.episode-page .time-display {
  font-size: 0.8rem;
  color: #fff;
  min-width: 70px;
  text-align: right;
}

.episode-page .episode-details {
  width: 100%;
  max-width: 600px;
  background: rgba(0, 0, 0, 0.7);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(136, 136, 136, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: 20px 10px;
  font-size: 0.9rem;
}

.episode-page .episode-title {
  font-size: 1.1rem;
  margin: 0 0 10px 0;
  color: #fff;
}

.episode-page .episode-details p {
  font-size: 0.8rem;
  margin: 5px 0;
  line-height: 1.5;
}

.episode-page .episode-details strong {
  color: #d4a017;
}

.episode-page::-webkit-scrollbar {
  display: none;
}

.episode-page {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 650px) {
  .episode-page .media-container {
    max-width: calc(100% - 20px);
  }
  .episode-page .media-placeholder {
    padding-top: 75%;
  }
  .episode-page .media-error {
    padding-top: 75%;
  }
  .episode-page .controls-inner {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    padding: 0 5px;
  }
  .episode-page .left-controls,
  .episode-page .right-controls {
    gap: 5px;
    flex-shrink: 0;
  }
  .episode-page .control-btn {
    font-size: 1rem;
    padding: 6px;
  }
  .episode-page .volume-slider {
    width: 40px;
  }
  .episode-page .time-display {
    font-size: 0.7rem;
    min-width: 60px;
  }
  .episode-page .episode-details {
    max-width: calc(100% - 50px);
    font-size: 0.8rem;
    padding: 10px;
  }
  .episode-page .episode-title {
    font-size: 1rem;
  }
  .episode-page .controls-overlay {
    width: 95%;
    height: 28px; 
  }
}

@media (max-width: 400px) {
  .episode-page .control-btn {
    font-size: 0.9rem;
    padding: 4px;
  }
  .episode-page .volume-slider {
    width: 30px;
  }
  .episode-page .time-display {
    font-size: 0.6rem;
    min-width: 50px;
  }
  .episode-page .controls-overlay {
    width: 94%;
    height: 24px; /* Further reduced for very small screens */
  }
}