.dynamic-loader {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 20px auto;
    animation: rotate 30s infinite linear; /* Counterclockwise rotation */
    will-change: transform;
  }
  
  /* Counterclockwise rotation (reverse direction) */
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }
  
  .dot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: var(--color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 4s infinite ease-in-out, /* Color and glow */
              wobble 3s infinite ease-in-out; /* Outward wobble */
    will-change: background-color, box-shadow, transform, opacity;
  }
  
  /* Perfectly centered circle, radius 35px */
  .dot1  { top: 60px; left: 95px; animation-delay: 0s, 0s; --angle: 0deg; } /* 0° */
  .dot2  { top: 77.5px; left: 91.9px; animation-delay: 0.33s, 0.43s; --angle: 30deg; } /* 30° */
  .dot3  { top: 91.9px; left: 77.5px; animation-delay: 0.66s, 0.76s; --angle: 60deg; } /* 60° */
  .dot4  { top: 95px; left: 60px; animation-delay: 1s, 1.1s; --angle: 90deg; } /* 90° */
  .dot5  { top: 91.9px; left: 42.5px; animation-delay: 1.33s, 1.43s; --angle: 120deg; } /* 120° */
  .dot6  { top: 77.5px; left: 28.1px; animation-delay: 1.66s, 1.76s; --angle: 150deg; } /* 150° */
  .dot7  { top: 60px; left: 25px; animation-delay: 2s, 2.1s; --angle: 180deg; } /* 180° */
  .dot8  { top: 42.5px; left: 28.1px; animation-delay: 2.33s, 2.43s; --angle: 210deg; } /* 210° */
  .dot9  { top: 28.1px; left: 42.5px; animation-delay: 2.66s, 2.76s; --angle: 240deg; } /* 240° */
  .dot10 { top: 25px; left: 60px; animation-delay: 3s, 3.1s; --angle: 270deg; } /* 270° */
  .dot11 { top: 28.1px; left: 77.5px; animation-delay: 3.33s, 3.43s; --angle: 300deg; } /* 300° */
  .dot12 { top: 42.5px; left: 91.9px; animation-delay: 3.66s, 3.76s; --angle: 330deg; } /* 330° */
  
  /* Pulse animation: Gold color and glow */
  @keyframes pulse {
    0% {
      background-color: var(--color);
      box-shadow: 0 0 8px var(--glow);
      opacity: 1;
    }
    20% {
      background-color: #ffd700;
      box-shadow: 0 0 15px rgba(255, 215, 0, 0.9), 0 0 25px rgba(255, 215, 0, 0.7);
      opacity: 0.9;
    }
    40% {
      background-color: #ffd700;
      box-shadow: 0 0 15px rgba(255, 215, 0, 0.9), 0 0 25px rgba(255, 215, 0, 0.7);
      opacity: 0.9;
    }
    60% {
      background-color: var(--color);
      box-shadow: 0 0 8px var(--glow);
      opacity: 1;
    }
    100% {
      background-color: var(--color);
      box-shadow: 0 0 8px var(--glow);
      opacity: 1;
    }
  }
  
  /* Outward wobble based on dot's angle */
  @keyframes wobble {
    0% { transform: translate(-50%, -50%) translate(0, 0); }
    20% { transform: translate(-50%, -50%) translate(calc(2px * cos(var(--angle))), calc(2px * sin(var(--angle)))); }
    40% { transform: translate(-50%, -50%) translate(calc(2.5px * cos(var(--angle))), calc(2.5px * sin(var(--angle)))); }
    60% { transform: translate(-50%, -50%) translate(calc(2px * cos(var(--angle))), calc(2px * sin(var(--angle)))); }
    80% { transform: translate(-50%, -50%) translate(calc(1px * cos(var(--angle))), calc(1px * sin(var(--angle)))); }
    100% { transform: translate(-50%, -50%) translate(0, 0); }
  }
  
  /* Dot-specific colors, glows, and angles */
  .dot1  { --color: #e63946; --glow: rgba(230, 57, 70, 0.9); }
  .dot2  { --color: #52b788; --glow: rgba(82, 183, 136, 0.9); }
  .dot3  { --color: #457b9d; --glow: rgba(69, 123, 157, 0.9); }
  .dot4  { --color: #f4a261; --glow: rgba(244, 162, 97, 0.9); }
  .dot5  { --color: #e63946; --glow: rgba(230, 57, 70, 0.9); }
  .dot6  { --color: #52b788; --glow: rgba(82, 183, 136, 0.9); }
  .dot7  { --color: #457b9d; --glow: rgba(69, 123, 157, 0.9); }
  .dot8  { --color: #f4a261; --glow: rgba(244, 162, 97, 0.9); }
  .dot9  { --color: #e63946; --glow: rgba(230, 57, 70, 0.9); }
  .dot10 { --color: #52b788; --glow: rgba(82, 183, 136, 0.9); }
  .dot11 { --color: #457b9d; --glow: rgba(69, 123, 157, 0.9); }
  .dot12 { --color: #f4a261; --glow: rgba(244, 162, 97, 0.9); }